@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop-sm {
  @media only screen and (min-width: 1000px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}
