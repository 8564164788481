.intro__heading-container {
  position: relative;
}
.intro__heading {
  margin-bottom: 0;
  font-size: 36px;
}
.intro__sub-heading {
  font-family: "Roboto-mono";
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 27px;
}
@media only screen and (min-width: 300px) {
  .intro__sub-heading {
    font-size: 15px;
  }
}
@media only screen and (min-width: 370px) {
  .intro__sub-heading {
    font-size: 18px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 768px) {
  .intro__sub-heading {
    line-height: 32px;
  }
}
@media only screen and (min-width: 1280px) {
  .intro__sub-heading {
    font-size: 30px;
    line-height: 33px;
  }
}
.intro__cta-container {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 39px 0px 53px;
  width: 100%;
}
@media only screen and (min-width: 1280px) {
  .intro__cta-container {
    gap: 16px;
  }
}
@media (min-width: 1400px) {
  .intro__cta-container {
    gap: 24px;
  }
}
.intro__cta-text {
  font-size: 22px;
}
.intro__text {
  margin-bottom: 0;
  line-height: 32px;
  font-size: 21px;
  font-family: "poppinsSemiBold";
  display: none;
}
@media only screen and (min-width: 1280px) {
  .intro__text {
    display: block;
    line-height: 33px;
  }
}
.intro__text--mobile {
  font-family: "poppinsSemiBold";
  margin: 0;
  font-size: 12px;
  line-height: 24px;
}
@media only screen and (min-width: 370px) {
  .intro__text--mobile {
    font-size: 17px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 768px) {
  .intro__text--mobile {
    line-height: 32px;
  }
}
@media only screen and (min-width: 1280px) {
  .intro__text--mobile {
    display: none;
  }
}
.intro__text--poppins-regular {
  font-family: "poppinsRegular";
}
.intro__detail {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01em;
  width: 100%;
  margin-top: 32px;
  text-align: center;
}
@media only screen and (min-width: 370px) {
  .intro__detail {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .intro__detail {
    line-height: 32px;
  }
}
@media only screen and (min-width: 1280px) {
  .intro__detail {
    text-align: left;
    line-height: 33px;
  }
}
.intro__slogan {
  text-align: center;
}
@media only screen and (min-width: 1280px) {
  .intro__slogan {
    text-align: left;
  }
}
.intro--hide-on-mobile {
  display: none;
}
@media only screen and (min-width: 1280px) {
  .intro--hide-on-mobile {
    display: block;
  }
}
@media only screen and (min-width: 1280px) {
  .intro--hide-on-desktop {
    display: none;
  }
}
.intro--inline-on-desktop {
  display: none;
}
@media only screen and (min-width: 1280px) {
  .intro--inline-on-desktop {
    display: inline-block;
  }
}

.eco-title.intro__heading.poppins-bold {
  font-size: 20px;
  line-height: 52px;
}
@media only screen and (min-width: 300px) {
  .eco-title.intro__heading.poppins-bold {
    font-size: 22px;
  }
}
@media only screen and (min-width: 370px) {
  .eco-title.intro__heading.poppins-bold {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1280px) {
  .eco-title.intro__heading.poppins-bold {
    font-size: 46px;
    line-height: inherit;
  }
}

.eco-title-logo {
  position: absolute;
  bottom: -5px;
  width: 80px;
}
@media only screen and (min-width: 1280px) {
  .eco-title-logo {
    width: auto;
  }
}
