.eco-game__heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}
.eco-game__heading-container .eco-game-logo {
  width: 322px;
  position: relative;
}
.eco-game__heading-container .eco-game-logo img {
  width: 100%;
}
.eco-game__heading-container .eco-game-logo .eco-title-logo {
  position: absolute;
  left: 150px;
  top: 68px;
}
.eco-game__heading-container .eco-game-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 439.8652648926px;
  stroke-dasharray: 439.8652648926px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.eco-game__heading-container .eco-game-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(249, 251, 179);
}
.eco-game__heading {
  margin: 0;
  color: #faff87;
}
@media only screen and (min-width: 1000px) {
  .eco-game__heading {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1280px) {
  .eco-game__heading {
    font-size: 36px;
  }
}
